.book-update-form {
  display: flex;
  padding: 30px;
  border-radius: 50px;
  background: var(--table-header);
  height: 100%;
}

.book-update-date {
  padding: 3px 20px 3px 3px;
  margin: 0;
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  min-width: fit-content;
  overflow: auto;
  gap: 5px;

  li {
    padding: 10px;
    border-radius: 50px;
    min-width: 160px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      color: var(--background);
      background-color: var(--border-active);
    }
    &.active {
      box-shadow: 0px 0px 0px 2px var(--table-header), 0px 0px 0px 3px var(--border-active)
    }
    &.selected {
      cursor: auto;
      color: var(--background);
      background-color: var(--border-active);
    }
  }
}

.book-update-config {
  width: 100%;
  padding: 10px;
  background: none;
  padding-left: 30px;

  color: var(--text-primary);
  border: 0;
  border-left: 1px solid var(--border);
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;

  &:focus-visible {
      outline: none !important;
      background: var(--footer-background);
  }

  &-wrap {
    width: 100%;
    height: 100%;
    padding-right: 20px;
    align-items: end;
    display: flex;
    flex-direction: column;
  }

  &.invalid-json {
    background-color: var(--form-valid-fail);
  }
}

div.book-submits {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.book-submit {
  color: var(--background);
  background-color: var(--border-active);
  padding: 10px 15px;
  border-radius: 50px;
  text-align: center;
  // min-width: 100px;
  width: fit-content;
  border: 0;
  outline: 0;
  cursor: pointer;

  &:disabled {
    background-color: var(--border);
  }
}

.center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 800px) {
  .book-update-form {
    flex-direction: column;
  }
  .book-update-date {
   flex-direction: row;
   overflow: auto;
   padding: 3px;
  }
  .book-update-config {
    border: 0;
    border-top: 1px solid var(--border);
    padding-left: 0px;
    margin-left: 0px;
    padding-top: 30px;
    margin-top: 10px;
    margin-bottom: 20px;
    &-wrap {
      padding-right: 0px;
    }
  }
}