.build-info {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-flow: row;
  transition: all 0.5s;
  opacity: 1;
  max-width: 400px;

  &-icon {
    display: inline-flex;
    font-style: normal;
    border: 2px solid var(--border);
    border-radius: 100px;
    text-align: center;
    flex-grow: 0;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s;
    background: transparent;
    color: var(--border);

    &:hover {
      background: var(--border);
      color: var(--background);
      border: 2px solid var(--background);
      box-shadow: 0px 0px 0px 5px var(--border);
    }
  }
  &-info {
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-width: 0px;
    transition: all 0.5s;

    li {
      white-space: nowrap;
      padding: 0 5px;
    }
  }

  &.open {
    background: var(--border);
    border-radius: 100px;

    .build-info {
      &-icon {
        background: var(--border);
        color: var(--background);
        border: 2px solid var(--background);
        box-shadow: 0px 0px 0px 5px var(--border);
      }
      &-info {
        padding: 0 20px;
        max-width: 320px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .build-info {
    opacity: 0;
    max-width: 0;
  }
}
