.footer-nav {
  margin: 0;
  padding: 5px 10px 4px;
  border-radius: 10px;
  border: 2px solid;
  display: flex;
  gap: 10px;

  li {
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
  }
}