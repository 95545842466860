.book-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.book-header {
  gap: 10px;
  display: flex;
  justify-content: center;
}

.book-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;

  .book-cover {
    display: flex;
    width: 33%;
    justify-content: left;

    .cover {
      max-height: 400px;
      align-self: center;
    }
    .cover i {
      width: 300px;
      height: 400px;
    }
  }
  .book-annotation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 33%;

    p {
      padding: 0 20px 0 60px;
    }
  }
  .book-chapters {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 33%;

    h2 {
      width: 100%;
      padding-right: 40px;
      text-align: center;
    }

    ul {
      padding: 0;

      display: block;
      overflow-y: auto;
      max-height: 250px;
      padding-right: 40px;
    }
  }
}


@media only screen and (max-width: 800px) {
  .book-content {
    flex-direction: column;

    .book-cover,
    .book-annotation,
    .book-chapters {
      width: auto;
      text-align: center;
      justify-content: center;
    }
    .book-cover,
    .book-annotation {
      padding-bottom: 50px;
    }
    .book-annotation {
      text-align: left;
    }
  }
}
