.theme-button {
  background-color: transparent;
  border: 2px solid var(--text-primary);
  border-radius: 10px;
  padding: 5px;
  line-height: 0;
  cursor: pointer;

  svg {
    fill: var(--text-primary);
    width: 20px;
  }
}
