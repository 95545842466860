.back-button {
  background: none;
  border-radius: 100px;
  border: 0;
  padding: 0;
  line-height: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;

  font-size: 24px;
  color: var(--text-primary);
  padding: 0;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;

  svg {
    left: -1px;
    width: 40px;
    margin-left: -40px;
    position: relative;
    stroke: var(--text-primary);
    fill: var(--background);
    transform: rotate(0deg);
  }
}
