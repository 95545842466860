.books {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: calc(50 * (100vw / 1280));
  padding: 0;
  list-style: none;

  & > li {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }

  ol {
    padding: 0;
  }
  ol li {
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
    text-align: center;
  }
  &-description {
    display: flex;
    width: 220px;
    justify-content: center;
    text-align: center;

    &-header {
      font-size: 25px;
      line-height: 1.3;
      margin-bottom: 10px;
      text-align: center;
    }

    &-author {
      line-height: 1;
      margin-top: 5px;
      font-weight: 400;
      font-size: 16px;
      font-style: italic;
      text-align: center;
    }

    &-date {
      margin-top: 5px;
      font-size: 14px;
      background-color: #555;
      padding: 5px 10px;
      border-radius: 4px;
    }
  }
  &-cover {
    display: flex;
    flex-grow: 0;
  }
}
