.logo {
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: var(--text-primary);
  font-size: 20px;

  img {
    height: calc(20px + 40 * (100vw / 1280));
    max-height: 60px;
    margin-right: 10px;
  }
}
