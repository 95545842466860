.main-page {
  display: flex;
  flex-direction: column;

  h1 {
    text-align: center;
  }
  > h2 {
    text-align: center;
    margin-top: calc(-10px - 3vw);
    margin-bottom: 50px;

    font-size: calc(21px + 1 * (100vw / 1280));
    font-weight: bold;
    text-align: center;
    line-height: 1;
    color: var(--text-primary);
    padding: 5px 0;

    display: flex;
    gap: 10px;
    text-align: center;
    justify-content: center;

    a {
      cursor: pointer;
    }
    span {
      position: relative;
      bottom: -2px;
    }
  }
}
