.about-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  h1,
  h2 {
    text-align: center;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &-image {
    display: flex;
    text-align: center;
    justify-content: center;
    height: calc(100vh - 400px - (50px + 3.75vw));
    position: relative;
    min-height: 200px;
    margin-top: calc(30px - 3.75vw);

    img {
      height: 100%;
      min-height: 200px;
    }
  }
  &-info {
    width: 90%;
    padding: 0 20px 20px;
    margin-top: 20px;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &-contacts {
    margin-top: 20px;
    text-align: center;
  }
}
