.error {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &-title {
    text-align: center;
    font-size: 150px;
    padding: 0;
    line-height: 150px;
    width: 100%;
    margin: 0;
  }
}
