.admin-page {
  display: flex;
  flex-direction: column;
  // height: 100%;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  h1,
  h2 {
    text-align: center;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
}
