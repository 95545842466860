.lang-menu {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;

  border: 2px solid var(--text-primary);
  border-radius: 10px;
  line-height: 10px;
  list-style: none;
  padding: 8px 5px;
  margin: 0;

  li {
    display: inline-block;
    line-height: 1;
  }

  a {
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: var(--contrast);
    }
  }

  .lang-menu-icon {
    font-size: 20px;
    line-height: 10px;
    position: relative;
    bottom: -3px;
  }
}

@media only screen and (max-width: 800px) {
  .build-info {
    opacity: 0;
    max-width: 0;
  }
}