.cover {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  background-color: var(--block-background);
  background-size: cover;
  background-position: center;
  box-shadow: 1px 1px 1px 0px var(--block-border);
  color: var(--primary-text);
  border-radius: 5px;
  padding: 2px 0;

  a,
  i {
    text-decoration: none;
    font-size: 20px;
    line-height: 25px;

    height: 300px;
    width: 220px;
    padding: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    b {
      display: flex;
      flex-grow: 1;
      padding-top: 20px;
    }

    span {
      display: flex;
      font-size: 12px;
      font-weight: normal;
    }
  }

  i b {
    font-weight: normal;
  }
  &.in-progress {
    opacity: 0.9;
    background-color: var(--border);
  }
}
