@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('../assets/fonts/Poppins-Light.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 600;
}
