.layout {
  height: 100vh;
  display: flex;
  flex-flow: column;

  &-header,
  &-main,
  &-footer {
    z-index: 1;
    display: flex;
    width: 100%;
  }

  &-header,
  &-main {
    padding: 0 40px;
    max-width: 1200px;
    margin: 0 auto;
  }

  &-header {
    padding-top: 50px;
    justify-content: center;
  }

  &-main {
    flex-grow: 1;
    flex-flow: column;
    padding-top: 20px;
    padding-bottom: 150px;
    justify-content: center;
  }

  &-logo {
    margin-right: 10px;
  }

  &-footer {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;
    padding: 30px;
    background: var(--footer-background);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

    &-name {
      display: inline-flex;
      white-space: nowrap;
      font-style: normal;
      transition: max-width 1s;
      max-width: 150px;
      overflow: hidden;
      margin-left: 0px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .layout-footer-name {
    max-width: 0;
    padding: 0px;
    margin-left: -5px;
  }
}
